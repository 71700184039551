import { queryOptions, useQuery, useSuspenseQuery } from "@tanstack/react-query";
import { API } from "../api";

export const teamMemberWagesQueryOptions = (teamId: string, memberId: string) =>
  queryOptions({
    queryKey: ["workspaceTeamMemberWages", teamId, memberId],
    queryFn: async () => {
      const response =
        await API.workspaceTeams.workspaceTeamsControllerFindMemberWages(
          teamId,
          memberId
        );
      return response.data;
    },
    enabled: !!teamId || !!memberId,
  });

export const useTeamMemberWages = (teamId: string, memberId: string) => {
  return useQuery(teamMemberWagesQueryOptions(teamId, memberId));
};

export const useTeamMemberWagesSuspense = (teamId: string, memberId: string) => {
  return useSuspenseQuery(teamMemberWagesQueryOptions(teamId, memberId));
};
