import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { RouterProvider } from "react-router-dom";
import { queryClient } from "@/api/client";
import { router } from "./modules/Router/router";
import { Toaster } from "./components/ui/toaster";
import { TourDayProvider } from "@/modules/Tours/TourDayContext";
import { useEffect } from "react";
import { saveInitialTheme } from "./lib/theme";
import "react-international-phone/style.css";

function App() {
  useEffect(() => {
    saveInitialTheme();
  }, []);

  return (
    <main>
      <QueryClientProvider client={queryClient}>
        <TourDayProvider>
          <RouterProvider router={router} />
          <ReactQueryDevtools initialIsOpen={false} />
          <Toaster />
        </TourDayProvider>
      </QueryClientProvider>
    </main>
  );
}

export default App;
