import {
  queryOptions,
  useQuery,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { API } from "../api";

export const estimateQueryOptions = (teamId: string, estimateId: string) =>
  queryOptions({
    queryKey: ["estimate", teamId, estimateId],
    queryFn: async () => {
      const response =
        await API.workspaceTeams.workspaceTeamsControllerGetEstimate(
          teamId,
          estimateId
        );
      return response.data;
    },
    enabled: !!teamId || !!estimateId,
  });

export const useEstimate = (teamId: string, estimateId: string) => {
  return useQuery(estimateQueryOptions(teamId, estimateId));
};

export const useEstimateSuspense = (teamId: string, estimateId: string) => {
  return useSuspenseQuery(estimateQueryOptions(teamId, estimateId));
};
