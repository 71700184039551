import { eachDayOfInterval, format, getDate } from "date-fns";

export function getDateFromIsoTimestampIgnoringTimezone(timestamp: string) {
  const [date] = timestamp.split("T");
  const [year, month, day] = date.split("-");
  return new Date(Number(year), Number(month) - 1, Number(day));
}

export function compareDatesIgnoringTimezones(
  dateOne: string | Date,
  dateTwo: string | Date
) {
  const dateOneDate =
    typeof dateOne === "string"
      ? getDateFromIsoTimestampIgnoringTimezone(dateOne)
      : new Date(
          dateOne.getFullYear(),
          dateOne.getMonth() - 1,
          dateOne.getDate()
        );
  const dateTwoDate =
    typeof dateTwo === "string"
      ? getDateFromIsoTimestampIgnoringTimezone(dateTwo)
      : new Date(
          dateTwo.getFullYear(),
          dateTwo.getMonth() - 1,
          dateTwo.getDate()
        );

  return getDate(dateOneDate) === getDate(dateTwoDate);
}

export const getFormatedTime = (date: Date) => {
  const isMidnight =
    date.getHours() === 0 && date.getMinutes() === 0 && date.getSeconds() === 0;
  const timePeriod = date.getHours() < 12 || isMidnight ? "am" : "pm";
  const formatedTime = format(date, `h:mm '${timePeriod}'`).toUpperCase();

  return formatedTime;
};

export const formatTime = (date: Date) => {
  return format(date, "HH:mm");
};

export const formatDate = (date: Date) => {
  return format(date, "dd-MMM-yyyy");
};

export const toApiDateFormat = (date: Date) => {
  return format(date, "yyyy-MM-dd");
};

export const getDaysForIntervalIgnoringTimezone = (
  startsDate: string,
  endsDate: string
) => {
  const startsAt = getDateFromIsoTimestampIgnoringTimezone(startsDate);
  const endsAt = getDateFromIsoTimestampIgnoringTimezone(endsDate);

  const days = eachDayOfInterval({
    start: startsAt,
    end: endsAt,
  });

  return days;
};

export const getDaysForInterval = (startsDate: string, endsDate: string) => {
  const days = eachDayOfInterval({
    start: startsDate,
    end: endsDate,
  });

  return days;
};

export const connectDateWithTime = (date: Date, time: string) => {
  const connectedDate = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    Number(time.slice(0, 2)),
    Number(time.slice(3, 5))
  );

  return connectedDate;
};

export const formatDateAndTime = (date: Date) => {
  const dateAndTime =
    formatDate(date) + ", " + date.toLocaleTimeString().substring(0, 5);

  return dateAndTime;
};
