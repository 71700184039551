import {
  queryOptions,
  useQuery,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { API } from "../api";

export const teamsQueryOptions = (workspaceId: string) =>
  queryOptions({
    queryKey: ["workspaceTeams", workspaceId],
    queryFn: async () => {
      const response = await API.workspaceTeams.workspaceTeamsControllerFindAll(
        { workspaceId }
      );
      return response.data;
    },
  });

export const useTeams = (workspaceId: string) => {
  return useQuery(teamsQueryOptions(workspaceId));
};

export const useTeamsSuspense = (workspaceId: string) => {
  return useSuspenseQuery(teamsQueryOptions(workspaceId));
};

