import { queryOptions, useQuery, useSuspenseQuery } from "@tanstack/react-query";
import { API } from "../api";

export const teamQueryOptions = (teamId: string) =>
  queryOptions({
    queryKey: ["workspaceTeam", teamId],
    queryFn: async () => {
      const response =
        await API.workspaceTeams.workspaceTeamsControllerFindOne(teamId);
      return response.data;
    },
    enabled: !!teamId,
  });

export const useTeam = (teamId: string) => {
  return useQuery(teamQueryOptions(teamId));
};

export const useTeamSuspense = (teamId: string) => {
  return useSuspenseQuery(teamQueryOptions(teamId));
};
