import {
  queryOptions,
  useQuery,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { API } from "../api";

type Query = {
  workspaceTeamMemberId?: string;
  tourId?: string;
};
export const teamEstimatesQueryOptions = (teamId: string, query?: Query) =>
  queryOptions({
    queryKey: ["teamEstimates", teamId, query],
    queryFn: async () => {
      const response =
        await API.workspaceTeams.workspaceTeamsControllerGetEstimates(
          teamId,
          query
        );
      return response.data;
    },
    enabled: !!teamId || !!query,
  });

export const useTeamEstimates = (teamId: string, query?: Query) => {
  return useQuery(teamEstimatesQueryOptions(teamId, query));
};

export const useTeamEstimatesSuspense = (teamId: string, query?: Query) => {
  return useSuspenseQuery(teamEstimatesQueryOptions(teamId, query));
};
