import { queryOptions, useQuery, useSuspenseQuery } from "@tanstack/react-query";
import { API } from "../api";

export const teamMembersQueryOptions = (teamId: string) =>
  queryOptions({
    queryKey: ["workspaceTeamMembers", teamId],
    queryFn: async () => {
      const response = await API.workspaceTeams.workspaceTeamsControllerFindMembers(
        teamId
      );
      return response.data;
    },
    enabled: !!teamId,
  });

export const useTeamMembers = (teamId: string) => {
  return useQuery(teamMembersQueryOptions(teamId));
};

export const useTeamMembersSuspense = (teamId: string) => {
  return useSuspenseQuery(teamMembersQueryOptions(teamId));
};
