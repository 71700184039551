import {
  queryOptions,
  useQuery,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { API } from "../api";

export const eventQueryOptions = (id: string) =>
  queryOptions({
    queryKey: ["event", id],
    queryFn: async () => {
      const response = await API.events.eventsControllerFindOne(id);
      return response.data;
    },
  });

export const useEvent = (id: string) => {
  return useQuery(eventQueryOptions(id));
};

export const useEventSuspense = (id: string) => {
  return useSuspenseQuery(eventQueryOptions(id));
};
